<script lang="ts" setup>
export interface Props {
  as?: string
}

const props = withDefaults(defineProps<Props>(), {
  as: 'div',
})
</script>

<template>
  <component :is="props.as" class="mx-auto px-2.5rem container">
    <slot />
  </component>
</template>

<style>

</style>
